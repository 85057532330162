import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
// import Slick from 'react-slick';
import mailchimp from '../images/partners/mailchimp.png';
// import settings from '../slider';

type PartnerType = Record<'name' | 'logo', string>;

// const partners: Array<PartnerType> = [
//   { name: 'Mailchimp', logo: 'mailchimp.png' },
//   { name: 'Mailchimp1', logo: 'mailchimp.png' },
//   { name: 'Mailchimp2', logo: 'mailchimp.png' },
//   { name: 'Mailchimp3', logo: 'mailchimp.png' },
// ];

const Partners: React.FC = () => (
  <VStack padding="2rem calc(var(--cp) + 1.5rem) 4rem" m={0}>
    <Text fontWeight={500} mb={3}>
      Trusted by thousands of customers and business all over the world
    </Text>
    {/* <Slick {...settings}>
      {partners.map(({ name }) => (
        <Image width="150px" key={name} src={mailchimp} />
      ))}
    </Slick> */}
  </VStack>
);

export default Partners;
