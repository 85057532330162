import {
  VStack,
  Grid,
  GridItem,
  Heading,
  Text,
  HStack,
  Button,
  Box,
} from '@chakra-ui/react';
import * as React from 'react';
import { Link } from 'gatsby';

import Layout from '../layout';
import Head from '../components/head';
import Hero from '../components/hero';
import WhyUs from '../components/why-us';
import Partners from '../components/partners';
import HowItWorks from '../components/how-it-works';

const Index: React.FC = () => (
  <Layout headerColorScheme="dark">
    <Head />

    <VStack bg="purple" pt="90px" pb={15} color="white" spacing={10}>
      <Hero />
      <Partners />
    </VStack>

    <Box padding="7rem calc(var(--cp) + 1.5rem)">
      <WhyUs />
    </Box>

    <Grid
      gap={3}
      id="services"
      boxSizing="border-box"
      padding={{
        md: '2rem calc(var(--cp) + 1rem) 3rem',
        base: '0 var(--cp) 2rem',
      }}
      templateRows={{ lg: '1fr 1fr', md: '1fr' }}
      templateColumns={{ lg: '1fr 1fr', md: '1fr' }}
    >
      <GridItem
        bg="purple"
        display="grid"
        borderRadius="xl"
        placeItems="center"
        boxSizing="border-box"
        px={{ md: 10, base: 8 }}
        py={{ md: 20, base: 10 }}
        colSpan={{ lg: 2, base: 1 }}
        rowSpan={{ lg: 1, base: 2 }}
      >
        <VStack
          color="white"
          spacing={3}
          align="center"
          textAlign="center"
          width={{ md: '500px', base: '100%' }}
        >
          <Heading size="lg">Digital Funds</Heading>
          <Text>
            Fast, reliable and smart trades should dig deep into your problems
            and not your pockets or busy schedule.
          </Text>
          <HStack pt={2}>
            <Link to="/currencies">
              <Button
                bg="aquamarine"
                color="purple"
                _hover={{ bg: 'aquamarine' }}
              >
                Trade Now!
              </Button>
            </Link>
            <Link to="/currencies">
              <Button
                variant="ghost"
                color="white"
                _hover={{ bg: 'transparent' }}
              >
                See our rates
              </Button>
            </Link>
          </HStack>
        </VStack>
      </GridItem>

      <GridItem
        bg="purple"
        color="white"
        display="grid"
        borderRadius="xl"
        py={10}
        position="relative"
        px={{ md: 10, base: 8 }}
        boxSizing="border-box"
        placeItems="center start"
      >
        <VStack
          textAlign="left"
          spacing={5}
          align="left"
          width={{ md: '300px', base: '100%' }}
        >
          <Heading size="lg">Shipping</Heading>
          <Text>
            For those who love shipping their items without worries. Find the
            best offers shipping your items from the USA
          </Text>
          <Box mt={15}>
            <Link to="/shipping">
              <Button
                bg="aquamarine"
                color="purple"
                _hover={{ bg: 'aquamarine' }}
              >
                Ship now!
              </Button>
            </Link>
          </Box>
        </VStack>
        {/* <Image
          position="absolute"
          width="120px"
          right={10}
          bottom={10}
          src=""
          alt="Ship fast"
        /> */}
      </GridItem>

      <GridItem
        bg="purple"
        display="grid"
        py={10}
        color="#fff"
        borderRadius="xl"
        position="relative"
        px={{ md: 10, base: 8 }}
        boxSizing="border-box"
        placeItems="center end"
      >
        <VStack
          textAlign="right"
          spacing={5}
          align="right"
          width={{ md: '300px', base: '100%' }}
        >
          <Heading size="lg">Ecommmerce</Heading>
          <Text>
            Jamestown introduces you to a world of alluring luxury. Find
            authentic, versatile and top-tier luxury shoes, collectibles,
            fragrances, cognacs and so much more endless possibilities.
          </Text>
          <Box mt={15}>
            <Link to="/shop">
              <Button
                bg="aquamarine"
                color="purple"
                _hover={{ bg: 'aquamarine' }}
              >
                Buy now!
              </Button>
            </Link>
          </Box>
        </VStack>
        {/* <Image
          position="absolute"
          left={0}
          bottom={0}
          src=""
          alt="Buy at ease"
        /> */}
      </GridItem>
    </Grid>

    <HowItWorks />
  </Layout>
);

export default Index;
