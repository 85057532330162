import React from 'react';
import {
  Box,
  Grid,
  GridItem,
  Heading,
  Image,
  Text,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import circle from '../images/circle.png';
import contactSRC from '../images/contact.png';
import conversationSRC from '../images/conversation.png';
import deliverSRC from '../images/deliver.png';

const How: React.FC<{
  icon: string;
  title: string;
  description: string;
}> = ({ title, description, icon }) => (
  <VStack align="center" textAlign="center">
    <Image
      src={icon}
      alt="title"
      width={{ md: '200px', base: '150px' }}
      maxWidth="90%"
      mb={5}
    />
    <Heading size="lg" color="purple">
      {title}
    </Heading>
    <Text>{description}</Text>
  </VStack>
);

const Header: React.FC<{ mb: string }> = ({ mb }) => (
  <Box position="relative" display="grid" mb={mb} placeItems="center">
    <Image src={circle} zIndex={0} position="absolute" width="90px" />
    <Heading position="absolute" textAlign="center" color="purple" size="lg">
      How it works
    </Heading>
  </Box>
);

const HowItWorks: React.FC = () => {
  const isSmallScreen = useBreakpointValue({
    lg: false,
    md: false,
    base: true,
  });

  return (
    <Box padding="3rem calc(var(--cp) + 1.5rem)" pt={{ base: '8rem' }}>
      {isSmallScreen && <Header mb="8rem" />}

      <Grid
        templateColumns={{ md: 'repeat(3, 1fr)', base: '1fr' }}
        my={{ md: '8rem', base: '4rem' }}
        gap={{ md: 10, base: 20 }}
      >
        <GridItem>
          <How
            icon={contactSRC}
            title="Contact"
            description="Right from your comfort zone, pick any of the services seamlessly"
          />
        </GridItem>

        <GridItem>
          {!isSmallScreen && <Header mb="8rem" />}

          <How
            icon={conversationSRC}
            title="Process"
            description="In the instant, we process all transactions in any and all preferred service"
          />
        </GridItem>

        <GridItem>
          <How
            icon={deliverSRC}
            title="Completion"
            description="No, it's not magic. We are Jamestown and we make things happen in real time."
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

export default HowItWorks;
