import React from 'react';
import { Box, VStack, HStack, Heading, Button } from '@chakra-ui/react';
import { Link } from 'gatsby';
import MapIllustration from '../images/svg/map.svg';

const Hero: React.FC = () => (
  <Box
    mt={5}
    as="section"
    display="grid"
    placeItems="center"
    padding={{ md: '0 calc(var(--cp) + 0.5rem)', base: 0 }}
    sx={{
      svg: {
        maxW: '100vw',
      },
    }}
  >
    <VStack
      width="600px"
      maxWidth="100vw"
      align="center"
      spacing={{ md: 8, base: 7 }}
      px={{ md: 0, base: 5 }}
      mb={20}
    >
      <Heading size="2xl" align="center" sx={{ span: { color: 'aquamarine' } }}>
        Trade <span>Digital Currencies</span> for your local Currency.
      </Heading>

      <HStack>
        <Link to="/currencies">
          <Button
            size="lg"
            color="purple"
            bgColor="aquamarine"
            _hover={{ bgColor: 'aquamarine' }}
          >
            Get Started
          </Button>
        </Link>

        <Link to="/about">
          <Button
            size="lg"
            variant="transparent"
            color="aquamarine"
            _hover={{
              bgColor: 'transparent',
            }}
          >
            Learn More
          </Button>
        </Link>
      </HStack>
    </VStack>

    <Box
      sx={{
        px: { md: 0, base: 2 },
        svg: {
          width: '100%',
          height: '100%',
        },
      }}
    >
      <MapIllustration />
    </Box>
  </Box>
);

export default Hero;
