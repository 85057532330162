import React from 'react';
import { Grid, GridItem, Heading, Text, VStack } from '@chakra-ui/react';
import Icon from 'feather-icons-react';

const WhyUs: React.FC = () => (
  <Grid templateColumns={{ md: '1fr 1fr 1fr', sm: '1fr 1fr' }} gap={8}>
    <GridItem as={VStack} spacing={5} align="left">
      <Icon icon="clock" size="48px" color="#00EFC1" />
      <Heading color="purple" size="lg">
        We are fast
      </Heading>
      <Text>
        With an unprecedented speed, we process transactions faster than the
        deadline.
      </Text>
    </GridItem>
    <GridItem as={VStack} spacing={5} align="left">
      <Icon icon="star" size="48px" color="#00EFC1" />
      <Heading color="purple" size="lg">
        We are reliable
      </Heading>
      <Text>
        We are a team that work relentlessly to make sure Jamestowners finish
        their trades with satisfaction.
      </Text>
    </GridItem>
    <GridItem as={VStack} spacing={5} align="left">
      <Icon icon="shield" size="48px" color="#00EFC1" />
      <Heading color="purple" size="lg">
        We are secure
      </Heading>
      <Text>
        Innovations are being tested and implemented daily to secure
        transactions. Here, trades happens instantly without hassle.
      </Text>
    </GridItem>
  </Grid>
);

export default WhyUs;
